import * as dashboardAction from "./dashboardAction";

export const dashboardFeatureKey = "dashboardInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  dashboard:{}
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case dashboardAction.GET_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardAction.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: payload.data,
      };
    case dashboardAction.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
