import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Spinner from "../app/views/shared/Spinner";
import UpdatePlan from "./views/pages/plan/UpdatePlan";
import ChangePassword from "./views/pages/changePassword/ChangePassword";
import ForgetPassword from "./views/pages/auth/ForgetPassword";

const Dashboard = lazy(() => import("./views/pages/dashboard/Dashboard"));
const List = lazy(() => import("./views/pages/List"));

const Blog = lazy(() => import("./views/pages/blog/Blog"));
const Detail = lazy(() => import("./views/pages/Detail"));
const User = lazy(() => import("./views/pages/user/User"));
const Admin = lazy(() => import("./views/pages/admin/Admin"));
const Plan = lazy(() => import("./views/pages/plan/Plan"));
const University = lazy(() => import("./views/pages/university/University"));
const Degree = lazy(() => import("./views/pages/degree/Degree"));
const Year = lazy(() => import("./views/pages/year/Year"));

const Subject = lazy(() => import("./views/pages/subject/Subject"));
const Topic = lazy(() => import("./views/pages/subject/Topic"));

const Edit = lazy(() => import("./views/pages/subject/Edit"));
const Update = lazy(() => import("./views/pages/subject/Update"));

const Error404 = lazy(() => import("./views/pages/error/Error404"));
const Error500 = lazy(() => import("./views/pages/error/Error500"));

const Login = lazy(() => import("./views/pages/auth/Login"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/forget-password" element={<ForgetPassword />} /> */}
        <Route exact path="/dashboard" element={<Dashboard />} />

        <Route exact path="/user/list" element={<List forUser />} />
        <Route exact path="/user/:id/detail" element={<User />} />

        <Route exact path="/subAdmin/list" element={<List forAdmin />} />
        <Route exact path="/subAdmin/create" element={<Admin />} />
        <Route exact path="/subAdmin/:id/detail" element={<Admin />} />

        <Route exact path="/blog/list" element={<List forBlog />} />
        <Route exact path="/blog/create" element={<Blog />} />
        <Route exact path="/blog/:id/detail" element={<Detail forBlog />} />
        <Route exact path="/blog/:id/update" element={<Blog />} />

        <Route exact path="/university/list" element={<List forUniversity />} />
        <Route exact path="/university/create" element={<University />} />
        <Route exact path="/university/:id/update" element={<University />} />

        <Route exact path="/degree/list" element={<List forDegree />} />
        <Route exact path="/degree/create" element={<Degree />} />
        <Route exact path="/degree/:id/update" element={<Degree />} />

        <Route exact path="/degree/:universityId/list" element={<List forDegreeList />} />
        <Route exact path="/year/degree/:degreeId/university/:universityId/list" element={<List forYearList />} />

        <Route exact path="/year/list" element={<List forYear />} />
        <Route exact path="/year/create" element={<Year />} />
        <Route exact path="/year/:id/update" element={<Year />} />

        <Route exact path="/subject/list" element={<List forSubject />} />
        <Route exact path="/subject/create" element={<Edit forSubject />} />
        <Route exact path="/subject/:id/detail" element={<Subject />} />
        <Route
          exact
          path="/subject/:subjectId/update"
          element={<Update forSubject />}
        />
        <Route
          exact
          path="/subject/:subjectId/topic/:topicId/flashcard/:flashCardId/detail"
          element={<Detail forFlashCard />}
        />
        <Route exact path="/subject/:subjectId/topic/:id" element={<Topic />} />
        <Route
          exact
          path="/subject/:subjectId/topic/create"
          element={<Edit forTopic />}
        />
        <Route
          exact
          path="/subject/:subjectId/topic/:topicId/update"
          element={<Update forTopic />}
        />
        <Route
          exact
          path="/subject/:subjectId/topic/:topicId/flashcard/create"
          element={<Edit forFlashCard />}
        />
        <Route
          exact
          path="/subject/:subjectId/topic/:topicId/flashcard/:flashCardId/update"
          element={<Update forFlashCard />}
        />
        <Route
          exact
          path="/subject/:id/topic/:topicId/pdf/create"
          element={<Edit forPdf />}
        />
        <Route
          exact
          path="/subject/:subjectId/topic/:topicId/pdf/:pdfId/update"
          element={<Update forPdf />}
        />
        <Route
          exact
          path="/subject/:subjectId/topic/:topicId/notes/create"
          element={<Edit forNotes />}
        />
        <Route
          exact
          path="/subject/:subjectId/topic/:topicId/video/create"
          element={<Edit forVideo />}
        />
        <Route
          exact
          path="/subject/:subjectId/topic/:topicId/video/:videoId/update"
          element={<Update forVideo />}
        />

        <Route exact path="/plan/list" element={<List forPlan />} />
        <Route exact path="/plan/create" element={<Plan />} />
        <Route exact path="/plan/:id/update" element={<UpdatePlan />} />

        <Route exact path="/subscriber/list" element={<List forSubscriber />} />

        <Route exact path="/enquire/list" element={<List forEnquire />} />
        <Route
          exact
          path="/enquire/:id/detail"
          element={<Detail forEnquire />}
        />

        <Route exact path="/stripe/list" element={<Update forStripe />} />
        <Route exact path="/change-password" element={<ChangePassword />} />

        <Route path="/404" element={<Error404 />} />
        <Route path="/500" element={<Error500 />} />
        <Route exact path="*" element={<Dashboard />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
