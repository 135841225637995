import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_PLAN_DETAILS_REQUEST = "GET_PLAN_DETAILS_REQUEST";
export const GET_PLAN_DETAILS_SUCCESS = "GET_PLAN_DETAILS_SUCCESS";
export const GET_PLAN_DETAILS_FAILURE = "GET_PLAN_DETAILS_FAILURE";

export const GET_PLAN_LIST_REQUEST = "GET_PLAN_LIST_REQUEST";
export const GET_PLAN_LIST_SUCCESS = "GET_PLAN_LIST_SUCCESS";
export const GET_PLAN_LIST_FAILURE = "GET_PLAN_LIST_FAILURE";

export const CREATE_PLAN_REQUEST = "CREATE_PLAN_REQUEST";
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_FAILURE = "CREATE_PLAN_FAILURE";

export const UPDATE_PLAN_REQUEST = "UPDATE_PLAN_REQUEST";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAILURE = "UPDATE_PLAN_FAILURE";

export const DELETE_PLAN_REQUEST = "DELETE_PLAN_REQUEST";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAILURE = "DELETE_PLAN_FAILURE";

export const getPlans = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PLAN_LIST_REQUEST });
      const url = `${BASE_URL}/plan/get/list/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url);
      dispatch({ type: GET_PLAN_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PLAN_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getPlansDetails = (planId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PLAN_DETAILS_REQUEST });
      const url = `${BASE_URL}/plan/get/details/${planId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_PLAN_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PLAN_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const createPlan = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_PLAN_REQUEST });
      const url = `${BASE_URL}/plan/create/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_PLAN_REQUEST, payload: response.data });
      toast.success("Plan Created Successfully");
      navigate("/plan/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_PLAN_REQUEST, payload: error.message });
    }
  };
};

export const updatePlan = (planId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PLAN_REQUEST });
      const url = `${BASE_URL}/plan/update/${planId}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_PLAN_SUCCESS, payload: response.data });
      toast.success("Plan Updated Successfully");
      navigate("/plan/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_PLAN_FAILURE, payload: error.message });
    }
  };
};

export const deletePlan = (planId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_PLAN_UPDATED" });
      dispatch({ type: DELETE_PLAN_REQUEST });
      const url = `${BASE_URL}/plan/delete/${planId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_PLAN_SUCCESS, payload: response.data });
      toast.success("Plan Deleted Successfully");
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_PLAN_FAILURE, payload: error.message });
    }
  };
};
