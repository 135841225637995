import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as loginAction from "../../../../redux/login/loginAction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data, "data");
    if (data.password !== data.confirmPassword) {
      return toast.error("New Password and Confirm Password are not matched!");
    }

    dispatch(loginAction.resetPasswordForAdmin(data, navigate));
  };
  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img
                  src={require("../../../../assets/images/logo.png")}
                  alt="logo"
                />
              </div>
              <h4>Hello! Admin</h4>
              <p>Change Your Password Here!</p>
              <Form className="pt-3" onSubmit={handleSubmit}>
                <Form.Group className="d-flex search-field mb-3">
                  <Form.Control
                    type="password"
                    id="newPassword"
                    value={data.password}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                    placeholder="Enter your New password"
                    required
                    size="lg"
                    className="h-auto"
                  />
                </Form.Group>
                <Form.Group className="d-flex search-field mb-3">
                  <Form.Control
                    type="password"
                    id="confirmPassword"
                    value={data.confirmPassword}
                    onChange={(e) =>
                      setData({ ...data, confirmPassword: e.target.value })
                    }
                    placeholder="Confirm password"
                    required
                    size="lg"
                    className="h-auto"
                  />
                </Form.Group>
                <div className="mt-3">
                  <button
                    className="btn_primary w-100"
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "brown",
                      color: "white",
                      border: "none",
                      outline: "none",
                      height: "40px",
                    }}
                  >
                    ChangePassword
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
