import * as subjectAction from "./subjectAction";

export const subjectFeatureKey = "subjectInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  subjects: [],
  topics: [],
  topicDetails: {},
  subject: {},
  flashCardDetails: {},
  pdfDetails: {},
  videoDetails: {},
  isSubjectUpdated: false,
  isTopicUpdated: false,
  isFlashCardUpdated: false,
  isVideoUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case subjectAction.CREATE_NEW_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.CREATE_NEW_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.CREATE_NEW_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get subject list

    case subjectAction.GET_ALL_SUBJECTS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_ALL_SUBJECTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: payload.data,
      };
    case subjectAction.GET_ALL_SUBJECTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subjects: [],
      };

    // get all topic list

    case subjectAction.GET_TOPICS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_TOPICS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        topics: payload.data,
      };
    case subjectAction.GET_TOPICS_LIST_FAILURE:
      return {
        ...state,
        loading: true,
        errorMsg: payload,
        topics: [],
      };

    // Topic Details including notes, flashCards and videos

    case subjectAction.GET_TOPICS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_TOPICS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        topicDetails: payload?.data,
      };
    case subjectAction.GET_TOPICS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        topicDetails: {},
      };

    // upload pdf
    case subjectAction.UPLOAD_PDF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.UPLOAD_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.UPLOAD_PDF_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // upload video

    case subjectAction.UPLOAD_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.UPLOAD_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // upload flashCards

    case subjectAction.UPLOAD_FLASHCARDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.UPLOAD_FLASHCARDS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.UPLOAD_FLASHCARDS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // upload Notes

    case subjectAction.CREATE_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.CREATE_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.CREATE_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Upload topics

    case subjectAction.CREATE_TOPIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.CREATE_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.CREATE_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get subject Details

    case subjectAction.GET_SUBJECT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_SUBJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        subject: payload.data,
      };
    case subjectAction.GET_SUBJECT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // check status for subject update

    case "IS_SUBJECT_UPDATED":
      return {
        ...state,
        isSubjectUpdated: false,
      };

    // check status for topic update

    case "IS_TOPIC_UPDATED":
      return {
        ...state,
        isTopicUpdated: false,
      };

    // check status for flash card update

    case "IS_FLASH_CARD_UPDATED":
      return {
        ...state,
        isFlashCardUpdated: false,
      };

    // check status for video update

    case "IS_VIDEO_UPDATED":
      return {
        ...state,
        isVideoUpdated: false,
      };

    // delete subject

    case subjectAction.DELETE_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubjectUpdated: true,
      };
    case subjectAction.DELETE_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // delete topic

    case subjectAction.DELETE_TOPIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.DELETE_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        isTopicUpdated: true,
      };
    case subjectAction.DELETE_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // delete flashCard

    case subjectAction.DELETE_FLASHCARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.DELETE_FLASHCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        isFlashCardUpdated: true,
      };
    case subjectAction.DELETE_FLASHCARD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // delete video

    case subjectAction.DELETE_PDF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.DELETE_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        isFlashCardUpdated: true,
      };
    case subjectAction.DELETE_PDF_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // delete pdf

    case subjectAction.DELETE_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        isFlashCardUpdated: true,
      };
    case subjectAction.DELETE_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // getFlash Card Details

    case subjectAction.GET_FLASH_CARD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_FLASH_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        flashCardDetails: payload.data?.[0],
      };
    case subjectAction.GET_FLASH_CARD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get pdf details

    case subjectAction.GET_PDF_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_PDF_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        pdfDetails: payload.data,
      };
    case subjectAction.GET_PDF_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get video Details

    case subjectAction.GET_VIDEO_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_VIDEO_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        videoDetails: payload.data,
      };
    case subjectAction.GET_VIDEO_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update flash Card
    case subjectAction.UPDATE_FLASHCARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.UPDATE_FLASHCARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.UPDATE_FLASHCARD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update pdf
    case subjectAction.UPDATE_PDF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.UPDATE_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.UPDATE_PDF_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update Video
    case subjectAction.UPDATE_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.UPDATE_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update subject

    case subjectAction.UPDATE_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.UPDATE_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update topic

    case subjectAction.UPDATE_TOPIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.UPDATE_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.UPDATE_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
