import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_SUBSCRIBER_LIST_REQUEST = "GET_SUBSCRIBER_LIST_REQUEST";
export const GET_SUBSCRIBER_LIST_SUCCESS = "GET_SUBSCRIBER_LIST_SUCCESS";
export const GET_SUBSCRIBER_LIST_FAILURE = "GET_SUBSCRIBER_LIST_FAILURE";

export const getSubscriberList = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBSCRIBER_LIST_REQUEST });
      const url = `${BASE_URL}/subscriber/list/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url);
      dispatch({ type: GET_SUBSCRIBER_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_SUBSCRIBER_LIST_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
