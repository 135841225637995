import React, { useEffect, useState } from "react";
import * as planAction from "../../../../redux/plan/planAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const UpdatePlan = () => {
  const planDetails = useSelector((state) => state.planInfo.planDetails);
  const [planForm, setPlanForm] = useState({
    title: "",
    planId: "",
    planType: "",
    planPrice: "",
    howManyMonthsYearsPlan: "",
    discount: null,
    calculatedPrice: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(planAction.getPlansDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setPlanForm({
        title: planDetails.title,
        planId: planDetails.planId,
        planType: planDetails.planType,
        planPrice: planDetails.planPrice,
        howManyMonthsYearsPlan: planDetails.howManyMonthsYearsPlan,
        discount: planDetails.discount,
        calculatedPrice: planDetails.calculatedPrice,
      });
    }
  }, [id, planDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "discount") {
      const discountValue = parseFloat(value);

      if (!isNaN(discountValue)) {
        const clampedDiscount = Math.min(100, Math.max(1, discountValue));
        const planPrice = parseFloat(planForm.planPrice);
        const finalPrice = planPrice - (planPrice * clampedDiscount) / 100;

        setPlanForm({
          ...planForm,
          discount: clampedDiscount.toFixed(0),
          calculatedPrice: finalPrice.toFixed(2),
        });
      } else {
        // Handle case when discount input is cleared
        setPlanForm({
          ...planForm,
          discount: "",
          calculatedPrice: "", // or set it to some default value
        });
      }
    } else if (name === "planPrice") {
      const planPrice = parseFloat(value);
      const discount = parseFloat(planForm.discount || 0);
      const finalPrice = planPrice - (planPrice * discount) / 100;

      setPlanForm({
        ...planForm,
        [name]: value,
        calculatedPrice: finalPrice.toFixed(2),
      });
    } else {
      // For other input fields
      setPlanForm({
        ...planForm,
        [name]: value,
      });
    }
  };

  const getCountOptions = () => {
    if (planForm.planType === "Daily") {
      return Array.from({ length: 30 }, (_, i) => i + 1);
    } else if (planForm.planType === "Month") {
      return Array.from({ length: 12 }, (_, i) => i + 1);
    } else if (planForm.planType === "Year") {
      return [1, 2, 3];
    } else {
      return [];
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(planAction.updatePlan(id, planForm, navigate));
  };

  const handleCancel = () => {
    navigate("/plan/list");
  };

  return (
    <>
      <div>
        <div className="page-header">
          <h3 className="page-title"> Create New Plan </h3>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Enter new Plan details</h4>

                <form className="form-sample" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 mb-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputUsername1">
                              Plan Name
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={planForm.title}
                              name="title"
                              placeholder="Plan name"
                              type="text"
                              id="exampleInputUsername1"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputUsername1">
                              Plan ID
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={planForm.planId}
                              name="planId"
                              placeholder="Plan Id"
                              type="text"
                              id="exampleInputUsername1"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <p className="mb-0">Plan Duration</p>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="planType">Basis</label>
                            <select
                              name="planType"
                              className="custom-select"
                              value={planForm.planType}
                              required
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                Select day, month, year
                              </option>
                              <option value="Daily">Day</option>
                              <option value="Month">Month</option>
                              <option value="Year">Year</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="howManyMonthsYearsPlan">
                              Count
                            </label>
                            <select
                              name="howManyMonthsYearsPlan"
                              className="custom-select"
                              required
                              value={planForm.howManyMonthsYearsPlan}
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                Select number
                              </option>
                              {getCountOptions().map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputUsername1">Price</label>
                            <input
                              onChange={handleInputChange}
                              value={planForm.planPrice}
                              name="planPrice"
                              placeholder="Enter Price"
                              type="text"
                              id="exampleInputUsername1"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputUsername1">
                              Discount
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={planForm.discount}
                              name="discount"
                              placeholder="Enter Discount"
                              type="number"
                              min={1}
                              max={100}
                              id="exampleInputUsername1"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputUsername1">
                              Final Price
                            </label>
                            <input
                              value={planForm.calculatedPrice}
                              name="calculatedPrice"
                              placeholder="Enter Price"
                              type="text"
                              id="exampleInputUsername1"
                              className="form-control"
                              required
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row"></div>
                  <button type="submit" className="btn btn-primary mr-2">
                    Update
                  </button>
                  <button className="btn btn-light" onClick={handleCancel}>
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdatePlan;
