import * as universityAction from "./universityAction";

export const universityFeatureKey = "universityInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  universities: [],
  universityDetails: {},
  isUniversityUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case universityAction.GET_UNIVERSITY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case universityAction.GET_UNIVERSITY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        universityDetails: payload.data,
      };
    case universityAction.GET_UNIVERSITY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        universityDetails: [],
      };
    case universityAction.GET_UNIVERSITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case universityAction.GET_UNIVERSITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        universities: payload.data
      };
    case universityAction.GET_UNIVERSITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case universityAction.CREATE_UNIVERSITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case universityAction.CREATE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUniversityUpdated: true,
      };
    case universityAction.CREATE_UNIVERSITY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case universityAction.UPDATE_UNIVERSITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case universityAction.UPDATE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUniversityUpdated: true,
      };
    case universityAction.UPDATE_UNIVERSITY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case universityAction.DELETE_UNIVERSITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case universityAction.DELETE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUniversityUpdated: true,
      };
    case universityAction.DELETE_UNIVERSITY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "IS_UNIVRSITY_UPDATED":
      return {
        ...state,
        isUniversityUpdated: false,
      };
    default:
      return state;
  }
};
