import * as enquireAction from "./enquireAction";

export const enquireFeatureKey = "enquireInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  enquire: [],
  enquireDetails: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case enquireAction.GET_ENQUIRE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case enquireAction.GET_ENQUIRE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        enquire: payload.data,
      };
    case enquireAction.GET_ENQUIRE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case enquireAction.GET_ENQUIRE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case enquireAction.GET_ENQUIRE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        enquireDetails: payload.data,
      };
    case enquireAction.GET_ENQUIRE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
